<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                src="@/assets/images/pages/login.png"
                alt="login"
                class="mx-auto"
              />
            </div>

            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
              style="min-height: 460px"
            >
              <form @keyup.enter="loginWithEmail($event)">
                <div class="p-8 login-tabs-container">
                  <div class="vx-card__title mb-4">
                    <h4 class="mb-4">K-VISIT Login</h4>
                    <p>Welcome back, please login to your account.</p>
                  </div>
                  <div>
                    <vs-input
                      name="email"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Email"
                      v-model="email"
                      class="w-full"
                    />

                    <vs-input
                      type="password"
                      name="password"
                      id="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Password"
                      v-model="password"
                      class="w-full mt-6"
                    />
                  <vs-input
                      type="password"
                      name="godkey"
                      id="godkey"
                      icon-no-border
                      icon="icon icon-shield"
                      icon-pack="feather"
                      label-placeholder="รหัสเจ้าหน้าที่ หากไม่มีให้เว้นว่างไว้"
                      v-model="godkey"
                      class="w-full mt-6"
                    />
                    <div class="flex flex-wrap justify-between my-5">
                      <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox> -->
                      <!-- <router-link to="">Forgot Password?</router-link> -->
                    </div>
                    <!-- <vs-button  type="border">Register</vs-button> -->
                    <vs-button
                      :disabled="!isValidatedForm"
                      type="filled"
                      @click.prevent="loginWithEmail"
                      class="float-right"
                      >Login</vs-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "firebase";
import VueCookies from "vue-cookies";
import { db, firebaseApp } from "../../store/firebaseDb";

Vue.use(VueCookies);

export default {
  data() {
    return {
      email: "",
      password: "",
      godkey: "",
      checkbox_remember_me: false,
    };
  },
  computed: {
    isValidatedForm() {
      return this.email !== "" && this.password !== "";
    },
  },
  methods: {
    getUserRole(cb) {
      console.log("[[getUserRole]]");

      const userInfo = $cookies.get("userInfo");
      console.log("getUserRole userInfo=", userInfo);
      Vue.$cookies.remove("customer_list");

      db.collection("users")
        .doc(userInfo.user.email)
        .get()
        .then((snapshot) => {
          const user = snapshot.data();
          console.log("user, roleInfo=", user);
          if ( user === null || user === undefined) {
          this.$vs.notify({
            color: "danger",
            title: "",
            text: "ผู้ใช้งานนี้ไม่มีข้อมูลในระบบ Cloud Management",
          });
            return ;
          }

          if ( user.customer_list !== undefined && user.customer_list.length > 0) {
            Vue.$cookies.set("customer_list", JSON.stringify(user.customer_list));
            Vue.$cookies.set("customer", user.customer_list[0]);
          } else {
            Vue.$cookies.set("customer", user.customer_name);
          }
          Vue.$cookies.set("roleInfo", user);

          const roleInfo = $cookies.get("roleInfo");
          console.log(" 111**this.roleInfo", roleInfo);

          const customerName = $cookies.get("customer");
          console.log(">>>customerName=" + customerName);

          this.$router.push("/").catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              throw error;
            }
          });

          // if(cb != null)cb()
          // console.log("user ", user);
          // do something with document
        });
    },

    loginWithEmail(e) {
      console.log("[[loginWithEmail]]");
      if (this.password === "" || this.password === null) {
              document.getElementById('password').focus();

        return;
      }

      firebaseApp
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          (user) => {
            console.log("user", user);
            // this.$router.replace("home");
            Vue.$cookies.set("userInfo", user);
            Vue.$cookies.remove("home");

            Vue.$cookies.set("pawd", this.password);

            if ( this.godkey === "2561") {
              Vue.$cookies.set("is_god", "1");
            } else {
              Vue.$cookies.set("is_god", "0");
            }



            // let thisGlo = this;
            this.getUserRole(function () {
              // thisGlo.$router.replace("/")
            });
          },
          (err) => {
            alert(err.message);
          }
        );
      e.preventDefault();
    },
    loginWithProvider(e) {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(
          (user) => {
            this.$router.replace("home");
          },
          (err) => {
            alert(err.message);
          }
        );
    },
  },
};
</script>

<style lang="scss">

.error { color: config('colors.red') }

#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
